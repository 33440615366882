<template>
  <div id="costId">
    <div
      id="filter-custom"
      class="page-container mb-2"
    >
      <h3
        class="header"
      >
        Bộ lọc
      </h3>
      <b-row>
        <!-- Người lao động -->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Tổ chức kiểm định<span class="required"></span></label>
            <v-select
              :reduce="label => label.id"
              label="name"
              :options="dataComboboxBusiness"
              :placeholder="'Tổ chức kiểm định'"
              @input="filterBussiness"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >

      <button-all-header
        :contentBtnAdd="'Thêm thiết bị kiểm định'"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="() => {}"
        @clickExportExcel="() => {}"
        @importFile="() => {}"
        @clickAdd="showModalCreate"
        @search="search($event)"
      />

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã người dùng -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ props.row.code }}</span>
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'dateAccreditation'">
            <span>
              {{ props.row.dateAccreditation | formatDateToDDMM }}
            </span>
          </span>

          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'ActionFunction'">

            <span
              class="ml-2"
              @click="showModalEdit(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

    </div>
  </div>

</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BButton,
  VBTooltip,
  BRow,
  BCol,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    // ModalCreate,
    ButtonAllHeader,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'MÃ KIỂM ĐỊNH',
          field: 'code',
          sortable: false,
        },
        {
          label: 'KIỂM ĐỊNH VIÊN',
          field: 'inspector',
          sortable: false,
        },
        {
          label: 'NGÀY KIỂM ĐỊNH',
          field: 'dateAccreditation',
          sortable: false,
        },
        {
          label: 'TỔ CHỨC KIỂM ĐỊNH',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        bussinessId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalType: '',
      totalRecord: 0,
      userId: '',
      dataDetailInjury: {},
      injuryId: '',
      modalIdCreate: 'modalIdCreate',
      modalIdEdit: 'modalIdEdit',
      dataList: [],
      dataComboboxBusiness: [],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
    this.fetchComboboxBusiness()
  },
  methods: {
    // Danh sách combobox doanh nghiệp
    async fetchComboboxBusiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_BUSSINESS)
      this.dataComboboxBusiness = data?.data
    },
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_ACCREDITATION, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm vs xóa
    showModalCreate() {
      this.modalType = 'add'
      this.$router.push({ name: 'inspect-equipment-add' })
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Show edit
    showModalEdit(id) {
      this.$router.push({ name: 'inspect-equipment-edit', params: { id } })
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_ACCREDITATION, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    filterBussiness(val) {
      if (val != null) {
        this.urlQuery.bussinessId = val
        this.urlQuery.pageNumber = 1
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.bussinessId = ''
        this.urlQuery.pageNumber = 1
        this.fetchData(this.urlQuery)
      }
    },
    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
#costId{
  .input-group-text {
    background: rgba(186, 191, 199, 0.12);
  }
}
#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }

}
</style>
